import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  CsQuestion,
  CsQuestionWithOptions,
  CsQuestionWithPlaceholder,
} from 'types/contentStack';
import { Question, QuestionWithOptionalPlaceholder } from 'types/forms';
import {
  CsClaimOptionQuestion,
  CsClaimOrPolicyQuestion,
  CsQuestionWithPlaceholderAndReasonOptions,
  QuestionWithOptions,
  QuestionWithPlaceholderAndReasonOptions,
} from './types';
import useQuestionProcessorHelper from './useQuestionProcessorHelper';

export type EmailUsQuestions = {
  name: QuestionWithOptionalPlaceholder;
  emailAddress: QuestionWithOptionalPlaceholder;
  postcode: QuestionWithOptionalPlaceholder;
  telephone: QuestionWithOptionalPlaceholder;
  bestTimeToContactClaims: QuestionWithOptions;
  bestTimeToContactPolicy: QuestionWithOptions;
  policyNumber: QuestionWithOptionalPlaceholder;
  petDob: Question;
  reason: QuestionWithPlaceholderAndReasonOptions;
  reasonSubfields: {
    additionalInformation: QuestionWithOptionalPlaceholder;
    policyUpdateDate: Question;
  };
  payment: QuestionWithOptions;
  enquiryType: {
    claims_text: string;
    policy_text: string;
  } & Question;
  claimOption: {
    make_claim_text: string;
    track_claim_text: string;
    ongoing_claim_text: string;
  } & Question;
};

type CsEmailUsQuestions = {
  csEmailUsFormCommon: {
    claims_or_my_policy: CsClaimOrPolicyQuestion;
    name: CsQuestionWithPlaceholder;
    email_address: CsQuestionWithPlaceholder;
    postcode: CsQuestionWithPlaceholder;
    telephone: CsQuestionWithPlaceholder;
    policy_number: CsQuestionWithPlaceholder;
    pet_date_of_birth: CsQuestion;
    additional_information: CsQuestionWithPlaceholder;
    payment: CsQuestionWithOptions;
  };
  csEmailUsFormPolicySpecific: {
    policy_contact_reason: CsQuestionWithPlaceholderAndReasonOptions;
    policy_update_date: CsQuestion;
    best_time_to_contact: CsQuestionWithOptions;
  };
  csEmailUsFormClaimsSpecific: {
    claim_options: CsClaimOptionQuestion;
    best_time_to_contact: CsQuestionWithOptions;
  };
};

const query = graphql`
  query {
    csEmailUsFormCommon {
      claims_or_my_policy {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        option_text {
          claim
          policy
        }
      }
      name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      email_address {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      telephone {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      policy_number {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      pet_date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      additional_information {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      payment {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        answer_options {
          name
          value
        }
      }
    }
    csEmailUsFormClaimsSpecific {
      claim_options {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        option_text {
          make_claim
          track_claim
          ongoing_claim
        }
      }
      best_time_to_contact {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        answer_options {
          name
          value
        }
      }
    }
    csEmailUsFormPolicySpecific {
      policy_contact_reason {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
        answer_options {
          name
          value
          display_additional_information_field
          display_policy_update_date_field
        }
      }
      policy_update_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      best_time_to_contact {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        answer_options {
          name
          value
        }
      }
    }
  }
`;

const useEmailUsQuestions = (): EmailUsQuestions => {
  const csEmailUsQuestions = useStaticQuery<CsEmailUsQuestions>(query);
  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
    processQuestionWithOptions,
  } = useQuestionProcessor(PageTitle.EmailUsForm);
  const { processQuestionWithPlaceholderAndReasonOptions } = useQuestionProcessorHelper();

  return {
    name: processQuestionWithOptionalPlaceholder(
      csEmailUsQuestions.csEmailUsFormCommon.name
    ),
    emailAddress: processQuestionWithOptionalPlaceholder(
      csEmailUsQuestions.csEmailUsFormCommon.email_address
    ),
    postcode: processQuestionWithOptionalPlaceholder(
      csEmailUsQuestions.csEmailUsFormCommon.postcode
    ),
    telephone: processQuestionWithOptionalPlaceholder(
      csEmailUsQuestions.csEmailUsFormCommon.telephone
    ),
    bestTimeToContactClaims: processQuestionWithOptions(
      csEmailUsQuestions.csEmailUsFormClaimsSpecific.best_time_to_contact
    ),
    bestTimeToContactPolicy: processQuestionWithOptions(
      csEmailUsQuestions.csEmailUsFormPolicySpecific.best_time_to_contact
    ),
    policyNumber: processQuestionWithOptionalPlaceholder(
      csEmailUsQuestions.csEmailUsFormCommon.policy_number
    ),
    petDob: processQuestion(csEmailUsQuestions.csEmailUsFormCommon.pet_date_of_birth),
    reason: processQuestionWithPlaceholderAndReasonOptions(
      csEmailUsQuestions.csEmailUsFormPolicySpecific.policy_contact_reason
    ),
    reasonSubfields: {
      additionalInformation: processQuestionWithOptionalPlaceholder(
        csEmailUsQuestions.csEmailUsFormCommon.additional_information
      ),
      policyUpdateDate: processQuestion(
        csEmailUsQuestions.csEmailUsFormPolicySpecific.policy_update_date
      ),
    },
    payment: processQuestionWithOptions(csEmailUsQuestions.csEmailUsFormCommon.payment),
    enquiryType: {
      ...processQuestion(csEmailUsQuestions.csEmailUsFormCommon.claims_or_my_policy),
      claims_text:
        csEmailUsQuestions.csEmailUsFormCommon.claims_or_my_policy.option_text.claim,
      policy_text:
        csEmailUsQuestions.csEmailUsFormCommon.claims_or_my_policy.option_text.policy,
    },
    claimOption: {
      ...processQuestion(csEmailUsQuestions.csEmailUsFormClaimsSpecific.claim_options),
      make_claim_text:
        csEmailUsQuestions.csEmailUsFormClaimsSpecific.claim_options.option_text
          .make_claim,
      track_claim_text:
        csEmailUsQuestions.csEmailUsFormClaimsSpecific.claim_options.option_text
          .track_claim,
      ongoing_claim_text:
        csEmailUsQuestions.csEmailUsFormClaimsSpecific.claim_options.option_text
          .ongoing_claim,
    },
  };
};

export default useEmailUsQuestions;
