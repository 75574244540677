import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { EmailUsFormData } from './types';

type EmailUsFormFields = {
  name: string;
  postcode: string;
  telephone: string;
  bestTimeToContact: string;
  policyNumber: string;
  petDob: string;
  reason: string;
  reasonAdditionalInfo: string;
  reasonPolicyUpdateDate: string;
  payment: string;
  enquiryType: string;
  claimOption: string;
};

const mapEmailUsFormFields = (emailUsFormValues: EmailUsFormData): EmailUsFormFields => ({
  ...emailUsFormValues,
  reasonPolicyUpdateDate:
    dateValueToISODateString(emailUsFormValues.reasonPolicyUpdateDate) ?? '',
  petDob: dateValueToISODateString(emailUsFormValues.petDob) ?? '',
});

export default mapEmailUsFormFields;
