import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import SelectInput from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import TextAreaInput from '@rsa-digital/evo-shared-components/components/Form/TextAreaInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { ErrorPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import emailClient from 'apiHelpers/email/emailClient';
import React, { ChangeEvent, useEffect, useState } from 'react';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import useClaimOptionsInfoCards from 'forms/EmailUsForm/useClaimOptionsInfoCards';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackFieldError,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackModalOpen,
  trackRadioButtonClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_ALPHANUMERIC, INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import useLoadingState from 'helpers/useLoadingState';
import {
  initialDateValue,
  initialDateValueWithDefaultDay,
} from 'state/formData/shared/dateValue';
import ConfirmationModal from './ConfirmationModal';
import mapEmailUsFormFields from './mapFields';
import useEmailUsQuestions from './questions';
import {
  FlexibleWidthDividerWithMargin,
  FormWithTopMargin,
  InFoCardInnerWrapper,
  InfoCardWithBottomMargin,
  StyledQuestionField,
} from './styles';
import {
  ClaimOptionInfoCardContents,
  ClaimOrPolicyOption,
  EmailUsFormData,
  ReasonOptions,
} from './types';
import useConfirmationModal from './useConfirmationModal';
import useEmailUsRules from './validation';

type EmailUsFormProps = {
  apiErrorMessage: string;
  submitButtonText: string;
};

const initialFormData: EmailUsFormData = {
  name: '',
  emailAddress: '',
  postcode: '',
  telephone: '',
  bestTimeToContact: '',
  policyNumber: '',
  petDob: initialDateValueWithDefaultDay,
  reason: '',
  reasonAdditionalInfo: '',
  reasonPolicyUpdateDate: initialDateValue,
  payment: '',
  enquiryType: '',
  claimOption: '',
};

const getDestinationCode = (enquiryType: ClaimOrPolicyOption | ''): string =>
  // Both variables are checked at build time in gatsby-config.js
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (enquiryType === ClaimOrPolicyOption.Claim
    ? process.env.GATSBY_EMAIL_DESTINATION_CODE_CLAIM
    : process.env.GATSBY_EMAIL_DESTINATION_CODE_POLICY)!;

enum ClaimOptions {
  MAKE_CLAIM = 'make-a-claim',
  TRACK_CLAIM = 'track-a-claim',
  ONGOING_CLAIM = 'ongoing-claim',
}

const EmailUsForm: React.FC<EmailUsFormProps> = ({
  apiErrorMessage,
  submitButtonText,
}) => {
  const [formData, updateFormData] = useState<EmailUsFormData>(initialFormData);
  const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);

  const openConfirmationModal = (): void => {
    setDisplayConfirmationModal(true);
    trackModalOpen('Email Us confirmation modal');
  };

  const closeConfirmationModal = (): void => {
    setDisplayConfirmationModal(false);
    trackTextButtonClick(PageTitle.EmailUsForm, 'Close - Email Us confirmation modal');
  };

  const emailUsQuestions = useEmailUsQuestions();
  const claimOptionInfoCards = useClaimOptionsInfoCards();
  const confirmationModal = useConfirmationModal();

  const selectedReason: ReasonOptions | undefined = emailUsQuestions.reason.options.find(
    (option: { value: string }) => option.value === formData.reason
  );

  const rules = useEmailUsRules(
    formData.enquiryType,
    selectedReason?.displayPolicyUpdateDate
  );

  const updateReason = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void =>
    updateFormData({
      ...formData,
      reason: e.target.value,
      // Reset the reason additional fields upon selection of a new reason
      reasonAdditionalInfo: '',
      reasonPolicyUpdateDate: { day: undefined, month: undefined, year: undefined },
    });

  const {
    isLoading: isSendingEmail,
    withLoadingState: withSendingEmailLoadingState,
  } = useLoadingState();

  const [hasApiError, setHasApiError] = useState(false);

  useEffect(() => {
    setHasApiError(false);
  }, [formData]);

  const handleSuccess = async (): Promise<void> =>
    withSendingEmailLoadingState(async () => {
      setHasApiError(false);
      try {
        await emailClient.sendEmail(
          getDestinationCode(formData.enquiryType),
          formData.emailAddress,
          mapEmailUsFormFields(formData)
        );
        openConfirmationModal();
        updateFormData(initialFormData);
      } catch (error) {
        setHasApiError(true);
      }
    });

  const { getError, validateOnSubmit, showValidation } = useValidation(
    formData,
    rules,
    trackFieldError
  );

  const showClaimOptions = formData.enquiryType === ClaimOrPolicyOption.Claim;
  const showPolicyOptions = formData.enquiryType === ClaimOrPolicyOption.Policy;

  const bestTimeToContactQuestion = formData.claimOption
    ? emailUsQuestions.bestTimeToContactClaims
    : emailUsQuestions.bestTimeToContactPolicy;

  const isClaimOptionOrPolicyReasonChosen = formData.claimOption || formData.reason;

  const getInfoCardContentFromClaimOption = (
    selectedClaimOption: string
  ): ClaimOptionInfoCardContents | undefined => {
    switch (selectedClaimOption) {
      case ClaimOptions.MAKE_CLAIM:
        return claimOptionInfoCards?.csEmailUsFormClaimsSpecific.make_claim;
      case ClaimOptions.TRACK_CLAIM:
        return claimOptionInfoCards?.csEmailUsFormClaimsSpecific.track_claim;
      case ClaimOptions.ONGOING_CLAIM:
        return claimOptionInfoCards?.csEmailUsFormClaimsSpecific.ongoing_claim;
      default:
        return undefined;
    }
  };

  const getInfoCardIcon = (
    infoCardContent: ClaimOptionInfoCardContents | undefined
  ): string | undefined =>
    infoCardContent !== undefined && infoCardContent.icon !== undefined
      ? unwrapSingleton(infoCardContent.icon)?.icon_code
      : undefined;

  const getInfoCardContent = (selectedClaimOption: string): JSX.Element => {
    const infoCardContent = getInfoCardContentFromClaimOption(selectedClaimOption);
    const icon = getInfoCardIcon(infoCardContent);

    return (
      <>
        {infoCardContent && (
          <FieldGrid alignLeft>
            <InFoCardInnerWrapper
              onClick={(e) => {
                if ((e.target as HTMLElement).tagName.toLowerCase() === 'a') {
                  trackTextButtonClick(
                    PageTitle.ContactUs,
                    (e.target as HTMLElement).innerText
                  );
                }
              }}>
              <InfoCardWithBottomMargin
                id="claimOptionInfoCard"
                heading={infoCardContent.heading}
                body={infoCardContent.info_card_text}
                headerIcon={icon}
              />
            </InFoCardInnerWrapper>
          </FieldGrid>
        )}
      </>
    );
  };

  return (
    <>
      {isSendingEmail && <LoadingOverlay loadingMessage="Submitting email us form" />}
      <FormWithTopMargin
        data-cy="Email Us form"
        onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
        <StyledQuestionField
          question={emailUsQuestions.enquiryType}
          errorText={getError('enquiryType')}
          initiallyShowTooltip>
          <RadioInput
            id="enquiryType"
            value={formData.enquiryType}
            options={[
              {
                name: emailUsQuestions.enquiryType.claims_text,
                value: ClaimOrPolicyOption.Claim,
              },
              {
                name: emailUsQuestions.enquiryType.policy_text,
                value: ClaimOrPolicyOption.Policy,
              },
            ]}
            onChange={(e) => {
              updateFormData({
                ...initialFormData,
                enquiryType: e.target.value as ClaimOrPolicyOption,
              });
              trackRadioButtonClick(
                emailUsQuestions.enquiryType.questionText,
                e.target.value
              );
            }}
          />
        </StyledQuestionField>
        {showClaimOptions && (
          <StyledQuestionField
            question={emailUsQuestions.claimOption}
            errorText={getError('claimOption')}>
            <RadioInput
              id="claimOption"
              value={formData.claimOption}
              options={[
                {
                  name: emailUsQuestions.claimOption.make_claim_text,
                  value: ClaimOptions.MAKE_CLAIM,
                },
                {
                  name: emailUsQuestions.claimOption.track_claim_text,
                  value: ClaimOptions.TRACK_CLAIM,
                },
                {
                  name: emailUsQuestions.claimOption.ongoing_claim_text,
                  value: ClaimOptions.ONGOING_CLAIM,
                },
              ]}
              onChange={(e) => {
                updateFormData({
                  ...formData,
                  claimOption: e.target.value,
                });
                trackRadioButtonClick(
                  emailUsQuestions.claimOption.questionText,
                  e.target.value
                );
              }}
            />
          </StyledQuestionField>
        )}
        {formData.claimOption && getInfoCardContent(formData.claimOption)}
        {showPolicyOptions && (
          <StyledQuestionField
            question={emailUsQuestions.reason}
            errorText={getError('reason')}>
            <SelectInput
              id="reason"
              value={formData.reason}
              placeholder={emailUsQuestions.reason.placeholder}
              options={emailUsQuestions.reason.options}
              onChange={(e) => {
                updateReason(e);
                trackFormDropdownSelect(
                  emailUsQuestions.reason.questionText,
                  e.target.value
                );
              }}
              onBlur={() => showValidation('reason')}
              onFocus={trackFormDropdownFocus(
                emailUsQuestions.reason.questionText,
                PageTitle.EmailUsForm
              )}
            />
          </StyledQuestionField>
        )}
        {!isClaimOptionOrPolicyReasonChosen && <FlexibleWidthDividerWithMargin />}
        {isClaimOptionOrPolicyReasonChosen && (
          <>
            <QuestionField question={emailUsQuestions.name} errorText={getError('name')}>
              <TextInput
                id="name"
                value={formData.name}
                placeholder={emailUsQuestions.name.placeholder}
                maxLength={100}
                onChange={(e) => {
                  if (e.target.value.match(INPUT_REGEX_NAME)) {
                    updateFormData({
                      ...formData,
                      name: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
                    });
                  }
                }}
                onBlur={() => showValidation('name')}
                onFocus={trackFormTextFieldFocus(emailUsQuestions.name.questionText)}
              />
            </QuestionField>
            <QuestionField
              question={emailUsQuestions.emailAddress}
              errorText={getError('emailAddress')}>
              <TextInput
                id="emailAddress"
                inputMode="email"
                value={formData.emailAddress}
                placeholder={emailUsQuestions.emailAddress.placeholder}
                maxLength={100}
                onChange={(e) =>
                  updateFormData({
                    ...formData,
                    emailAddress: e.target.value,
                  })
                }
                onBlur={() => showValidation('emailAddress')}
                onFocus={trackFormTextFieldFocus(
                  emailUsQuestions.emailAddress.questionText
                )}
              />
            </QuestionField>
            <QuestionField
              question={emailUsQuestions.postcode}
              errorText={getError('postcode')}>
              <TextInput
                id="postcode"
                value={formData.postcode}
                placeholder={emailUsQuestions.postcode.placeholder}
                maxLength={10}
                onChange={(e) => {
                  if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC)) {
                    updateFormData({
                      ...formData,
                      postcode: e.target.value.toUpperCase(),
                    });
                  }
                }}
                onBlur={() => showValidation('postcode')}
                onFocus={trackFormTextFieldFocus(emailUsQuestions.postcode.questionText)}
              />
            </QuestionField>
            <QuestionField
              question={emailUsQuestions.telephone}
              errorText={getError('telephone')}>
              <TextInput
                id="telephone"
                numbersOnly
                inputMode="tel"
                value={formData.telephone}
                placeholder={emailUsQuestions.telephone.placeholder}
                maxLength={11}
                onChange={(e) =>
                  updateFormData({
                    ...formData,
                    telephone: e.target.value,
                  })
                }
                onBlur={() => showValidation('telephone')}
                onFocus={trackFormTextFieldFocus(emailUsQuestions.telephone.questionText)}
              />
            </QuestionField>
            <QuestionField question={bestTimeToContactQuestion}>
              <RadioInput
                id="bestTimeToContact"
                value={formData.bestTimeToContact}
                options={bestTimeToContactQuestion.options}
                onChange={(e) => {
                  updateFormData({
                    ...formData,
                    bestTimeToContact: e.target.value,
                  });
                  trackRadioButtonClick(
                    bestTimeToContactQuestion.questionText,
                    e.target.value
                  );
                }}
              />
            </QuestionField>
            <QuestionField question={emailUsQuestions.policyNumber}>
              <TextInput
                id="policyNumber"
                value={formData.policyNumber}
                placeholder={emailUsQuestions.policyNumber.placeholder}
                maxLength={50}
                onChange={(e) => {
                  updateFormData({
                    ...formData,
                    policyNumber: e.target.value,
                  });
                }}
                onFocus={trackFormTextFieldFocus(
                  emailUsQuestions.policyNumber.questionText
                )}
                numbersOnly
              />
            </QuestionField>
            <QuestionField
              question={emailUsQuestions.petDob}
              errorText={getError('petDob')}>
              <DateInput
                id="petDob"
                value={formData.petDob}
                onChange={(e) => {
                  updateFormData({
                    ...formData,
                    petDob: e,
                  });
                }}
                onBlur={() => showValidation('petDob')}
                onFocus={trackFormTextFieldFocus(emailUsQuestions.petDob.questionText)}
              />
            </QuestionField>
            {selectedReason?.displayPolicyUpdateDate && (
              <QuestionField
                question={emailUsQuestions.reasonSubfields.policyUpdateDate}
                errorText={getError('reasonPolicyUpdateDate')}>
                <DateInput
                  id="reasonPolicyUpdateDate"
                  value={formData.reasonPolicyUpdateDate}
                  onChange={(e) => {
                    updateFormData({
                      ...formData,
                      reasonPolicyUpdateDate: e,
                    });
                  }}
                  onBlur={() => showValidation('reasonPolicyUpdateDate')}
                  onFocus={trackFormTextFieldFocus(
                    emailUsQuestions.reasonSubfields.policyUpdateDate.questionText
                  )}
                />
              </QuestionField>
            )}
            {(selectedReason?.displayAdditionalInformation || formData.claimOption) && (
              <QuestionField
                question={emailUsQuestions.reasonSubfields.additionalInformation}>
                <TextAreaInput
                  id="reasonAdditionalInfo"
                  value={formData.reasonAdditionalInfo}
                  placeholder={
                    emailUsQuestions.reasonSubfields.additionalInformation.placeholder
                  }
                  maxLength={1000}
                  onChange={(e) => {
                    updateFormData({
                      ...formData,
                      reasonAdditionalInfo: e.target.value,
                    });
                  }}
                  onFocus={trackFormTextFieldFocus(
                    emailUsQuestions.reasonSubfields.additionalInformation.questionText
                  )}
                />
              </QuestionField>
            )}
            <QuestionField
              question={emailUsQuestions.payment}
              errorText={getError('payment')}>
              <RadioInput
                id="payment"
                value={formData.payment}
                options={emailUsQuestions.payment.options}
                onChange={(e) => {
                  updateFormData({
                    ...formData,
                    payment: e.target.value,
                  });
                  trackRadioButtonClick(
                    emailUsQuestions.payment.questionText,
                    e.target.value
                  );
                }}
              />
            </QuestionField>
            <div aria-live="assertive" role="alert">
              {hasApiError && (
                <ErrorPanel>
                  <RichText html={apiErrorMessage} />
                </ErrorPanel>
              )}
            </div>
            <FormFooter
              moveNextButton={{
                text: submitButtonText,
                disabled: isSendingEmail,
                onClick: () =>
                  trackTextButtonClick(PageTitle.EmailUsForm, 'Submit details'),
              }}
              pageTitle={PageTitle.EmailUsForm}
            />
          </>
        )}
      </FormWithTopMargin>
      {displayConfirmationModal && (
        <ConfirmationModal
          onClose={closeConfirmationModal}
          title={confirmationModal.title}
          body={confirmationModal.body}
          image={confirmationModal.image}
        />
      )}
    </>
  );
};

export default EmailUsForm;
