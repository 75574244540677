import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { CsIcon, CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import { Question, QuestionWithPlaceholder } from 'types/forms';

export type CsQuestionWithPlaceholderAndReasonOptions = {
  answer_options: {
    name: string;
    value: string;
    display_policy_update_date_field: boolean;
    display_additional_information_field: boolean;
  }[];
} & CsQuestionWithPlaceholder;

export type CsClaimOrPolicyQuestion = {
  option_text: {
    claim: string;
    policy: string;
  };
} & CsQuestion;

export type CsClaimOptionQuestion = {
  option_text: {
    make_claim: string;
    track_claim: string;
    ongoing_claim: string;
  };
} & CsQuestion;

export type QuestionOptions = {
  name: string;
  value: string;
};

export type QuestionWithOptions = {
  options: QuestionOptions[];
} & Question;

export type ReasonOptions = {
  displayAdditionalInformation: boolean;
  displayPolicyUpdateDate: boolean;
} & QuestionOptions;

export type QuestionWithPlaceholderAndReasonOptions = {
  options: ReasonOptions[];
} & QuestionWithPlaceholder;

export enum ClaimOrPolicyOption {
  Claim = 'claim',
  Policy = 'policy',
}

export type ClaimOptionInfoCardContents = {
  heading: string;
  icon: [CsIcon];
  info_card_text: string;
};

export type EmailUsFormData = {
  name: string;
  emailAddress: string;
  postcode: string;
  telephone: string;
  bestTimeToContact: string;
  policyNumber: string;
  petDob: DateValue;
  reason: string;
  reasonAdditionalInfo: string;
  reasonPolicyUpdateDate: DateValue;
  payment: string;
  enquiryType: ClaimOrPolicyOption | '';
  claimOption: string;
};
