import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { StyledLabel } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Label/styles';
import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const StyledQuestionField = styled(QuestionField)`
  && ${StyledLabel} {
    & {
      font-weight: bold;
    }
  }
`;

export const InfoCardWithBottomMargin = styled(InfoCard)`
  margin-top: 0;
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const FormWithTopMargin = styled.form`
  margin-top: ${spacing(6)};
`;

export const FlexibleWidthDividerWithMargin = styled(FlexibleWidthDivider)`
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}

  margin-top: ${spacing(8)};
`;

export const InFoCardInnerWrapper = styled.div`
  margin-top: ${spacing(2)};
`;
